import React from "react";
//MUI Components
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
//MUI Icons
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
//Custome Components
import IconCard from "../Cards/IconCard";
//Hook MSAL
import { useMsal } from "@azure/msal-react";
//import OpeningApp from "./OpeningApp";

export default function Auth() {
    const { instance } = useMsal();
    function handleLogin() {
        instance.loginRedirect({ scopes: ["User.Read"] }).catch((e) => {
            console.error(e);
        });
    }

    return (
        <Container>
            <IconCard title="Connexion" icon={LoginRoundedIcon}>
                Bienvenue sur le portail de formation de l'École Professionnelle
                de la Boucherie de Paris !
                <Alert sx={{ my: 1 }} severity="info">
                    Pour accéder au Portail Apprenti, vous devez vous connecter
                    :
                </Alert>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        mt: 3,
                    }}
                >
                    <Button
                        variant="contained"
                        color="info"
                        sx={{ mx: "auto", alignSelf: "center", width: "60%" }}
                        onClick={() => handleLogin()}
                    >
                        Se Connecter
                    </Button>
                </Box>
            </IconCard>
        </Container>
    );
}

//  <OpeningApp />
